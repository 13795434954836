import { union } from './icons.js';
import setClassName from '../../helpers/setClassName.js';
import Decycler from '@insight/common/decycler/decycler.js';
import { CaseFilter } from './CaseFilter.js';
import { IntlShape } from 'react-intl';
import { CaseFilterType } from './CaseFilterType.js';

export class CaseFilterUnion extends CaseFilter {

    execute(eventsfile: string) {
        return Promise.all(this.children.map(child => {
            return child.execute(eventsfile);
        }))
            .then(childResults => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                let caseIds = childResults.shift()!;
                let childCaseIds: number[] | undefined;
                while ((childCaseIds = childResults.shift()) !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    caseIds = caseIds.filter(id => childCaseIds!.includes(id));
                }
                return caseIds;
            })
    }

    label(intl: IntlShape) {
        const msg = intl.formatMessage({
            id: "filter.msg.union",
            defaultMessage: "Cases of all {count} sub-filters",
            description: "",
        }, { count: this.children.length })
        return msg;
    }
}
setClassName(CaseFilterUnion, "CaseFilterUnion"); // for minifying purposes when using constructor.name
Decycler.registerSerializableType(CaseFilterUnion);

new CaseFilterType({
    id: "union",
    name: intl => intl.formatMessage({
        id: "filter.def.union.name",
        defaultMessage: "Union",
        description: "Name of union filter.",
    }),
    description: intl => intl.formatMessage({
        id: "filter.def.union.description",
        defaultMessage: "Union of all child filters",
        description: "Description of union filter.",
    }),
    fieldDefinitions: [],
    childrenAllowed: true,
    icon: union,
    class: CaseFilterUnion,
})