import { intersection } from './icons.js';
import { CaseFilterType } from './CaseFilterType.js';
import { CaseFilter } from './CaseFilter.js';
import setClassName from '../../helpers/setClassName.js';
import Decycler from '@insight/common/decycler/decycler.js';
import { IntlShape } from 'react-intl';

export class CaseFilterIntersection extends CaseFilter {
    execute(eventsfile: string) {
        return Promise.all(this.children.map(child => {
            return child.execute(eventsfile);
        }))
            .then(childResults => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                let caseIds = childResults.shift()!;
                let childCaseIds: number[] | undefined;
                while ((childCaseIds = childResults.shift()) !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    caseIds = caseIds.filter(id => childCaseIds!.includes(id));
                }
                return caseIds;
            })
    }
    label(intl: IntlShape) {
        const msg = intl.formatMessage({
            id: "filter.msg.intersection",
            defaultMessage: "Cases contained in all {count} sub-filters",
            description: "",
        }, { count: this.children.length })
        return msg;

    }
}
setClassName(CaseFilterIntersection, CaseFilterIntersection.name); // for minifying purposes when using constructor.name
Decycler.registerSerializableType(CaseFilterIntersection);

new CaseFilterType({
    id: "intersection",
    name: intl => intl.formatMessage({
        id: "filter.def.intersect.name",
        defaultMessage: "Intersect",
        description: "Name of intersection filter.",
    }),
    description: intl => intl.formatMessage({
        id: "filter.def.intersect.description",
        defaultMessage: "Intersection of all child filters",
        description: "Description of intersection filter.",
    }),
    fieldDefinitions: [],
    childrenAllowed: true,
    icon: intersection,
    class: CaseFilterIntersection,
})
