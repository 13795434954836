import { Icon } from '@blueprintjs/core';
import React from 'react';
import { sendRequest } from '../Request.js';
import Decycler from '@insight/common/decycler/decycler.js';
import setClassName from '../../helpers/setClassName.js';
import { CaseFilterType } from './CaseFilterType.js';
import { CaseFilter } from './CaseFilter.js';
import { CaseFilterChoicesFieldDefinition, FieldType } from './CaseFilterFieldDefinition.js';
import { CaseFilterField } from './CaseFilterField.js';

export class CaseFilterCaseAttribute extends CaseFilter {
    data!: CaseFilter['data'] & {
        operators: string[] | undefined;
        fieldType: FieldType;
        commonValues: string[] | undefined;
    }

    constructor(name: string,
        description: string | undefined,
        type: CaseFilterType,
    ) {
        super(name, description, type);
    }

    initData() {
        super.initData();
        this.data = {
            ...this.data,
            operators: [],
            fieldType: FieldType.UNKNOWN,
            commonValues: []
        }
    }

    getOperators() {
        return this.data.operators
    }

    getCommonValues() {
        return this.data.commonValues;
    }

    execute(eventsfile: string) {
        const query = new URLSearchParams({
            eventsfile,
        });
        return sendRequest<number[], { [key: string]: unknown }>("case_attribute_filter?" + query.toString(), {
            method: "post",
            data: this.fieldValues()
        })
    }

    onChange(field: CaseFilterField, value: string) {
        switch (field.definition.id) {
            case "attributeName": {
                const selectedAttribute = this.data.graph?.caseAttributeDefinitions.get(value)
                if (selectedAttribute) {
                    switch (selectedAttribute.data.datatype) {
                        case "string":
                            this.data.operators = ["="];
                            this.data.commonValues = selectedAttribute?.stringValues();
                            this.fields["attributeValue"].definition.type = FieldType.STRING;
                            break;
                        case "number":
                            this.data.operators = ["<", "=", ">"];
                            this.data.commonValues = []
                            this.fields["attributeValue"].definition.type = FieldType.FLOAT;
                            break;
                        default:
                            this.fields["operator"].definition.visible = false;
                            this.data.operators = []
                            this.fields["attributeValue"].definition.type = FieldType.STRING;
                    }
                }
                break;
            }
        }
    }

    getOptions(field: CaseFilterField): string[] | undefined {
        let result;
        switch (field.definition.id) {
            case "attributeName":
                return this.data.graph !== undefined ? [...this.data.graph.caseAttributeDefinitions.keys()].sort() : []
                break;
            case "operator":
                return this.data.operators;
                break;
            case "attributeValue":
                return this.data.commonValues;
                break;
        }
        return result;
    }

    label() {
        let msg = "";
        if (this.fields.attributeName && this.fields.operator && this.fields.attributeValue) {
            msg = `${this.fields.attributeName.value} ${this.fields.operator} "${this.fields.attributeValue}"`
        }
        return msg;
    }
}
setClassName(CaseFilterCaseAttribute, CaseFilterCaseAttribute.name); // for minifying purposes when using constructor.name
Decycler.registerSerializableType(CaseFilterCaseAttribute);

new CaseFilterType({
    id: "caseattribute",
    name: intl => intl.formatMessage({
        id: "filter.def.case-attribute.name",
        defaultMessage: "Case attributes",
        description: "Name of of case attributes filter.",
    }),
    description: intl => intl.formatMessage({
        id: "filter.def.case-attribute.description",
        defaultMessage: 'Cases anzeigen, auf die der Vergleich zutrifft',
        description: "Description of case filter",
    }),
    fieldDefinitions: [
        new CaseFilterChoicesFieldDefinition({
            id: "attributeName",
            name: intl => intl.formatMessage({
                id: "filter.def.case-attribute.attribute",
                defaultMessage: "Attribut",
                description: "Description select label for case attribute",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: true,
            vector: false,
        }),

        new CaseFilterChoicesFieldDefinition({
            id: "operator",
            name: intl => intl.formatMessage({
                id: "filter.def.case-attribute.operator",
                defaultMessage: "Operator",
                description: "Name of \"operator\" parameter",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: false,
            vector: false,
        }),

        new CaseFilterChoicesFieldDefinition({
            id: "attributeValue",
            name: intl => intl.formatMessage({
                id: "filter.def.case-attribute.value",
                defaultMessage: "Vergleichswert",
                description: "Name of reference value to compare to",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.UNDEFINED,
            required: true,
            visible: false,
            vector: false,
        }),
    ],
    childrenAllowed: false,
    icon: <Icon icon="filter"/>,
    class: CaseFilterCaseAttribute,
})