import { IntlShape } from 'react-intl';
import React from 'react';
import { useActor } from "@xstate/react";
import {
    Button,
    Classes,
    Dialog,
} from '@blueprintjs/core';

import { Grid } from './Grid/Grid.js';
import { View } from './View/View.js';
import displayModeService from '../classes/displayModeService.js';
import DataProvider from '../classes/data_provision/DataProvider.js';
import { SetProgressContext } from './ProgressOverlay.js';

export interface HomePageProps extends React.JSX.IntrinsicAttributes {
    intl: IntlShape;
    dataProvider: DataProvider
}

let prevContext = {};
export default function Homepage(props: HomePageProps) {

    console.log(`*** Render Homepage`);

    const [displayMode] = useActor(displayModeService);
    const [dataProvisionState, dataProvisionSend] = useActor(props.dataProvider.service);
    const single = displayMode.matches("single");
    const components = [];

    const intl = props.intl;

    const progressOverlay = React.useContext(SetProgressContext);
    React.useEffect(() => {
        if (['loading_sizes', 'loading'].some(dataProvisionState.matches) && progressOverlay) {
            progressOverlay.setIsOpen(true);
            progressOverlay.setLabel("Loading");
        }
        else {
            progressOverlay?.setIsOpen(false);
        }
    }, [dataProvisionState])

    console.log(`--- Homepage ${prevContext !== dataProvisionState.context?"**":""}`)
    prevContext = dataProvisionState.context;

    const hasData = dataProvisionState.context.dataControllers.graphController.data.edges.length > 0
    if (!dataProvisionState.matches('no_data') && !dataProvisionState.matches('error') && hasData) {
        if (single) {
            components.push(
                <div id="desk" key={components.length + 1}>
                    <Grid columns="5" rows="10">
                        <View
                            id="view_1"
                            top={1}
                            left={1}
                            bottom={10}
                            right={5}
                            key="1"
                            dataProvisionContext={dataProvisionState.context}
                        />
                    </Grid>
                </div>
            )
        }
        else {
            components.push(
                <div id="desk" key={components.length + 1}>
                    <Grid columns="10" rows="10">
                        <View
                            id="view_1"
                            top={1}
                            left={1}
                            bottom={10}
                            right={5}
                            key="1"
                            dataProvisionContext={dataProvisionState.context}
                        />
                        <View
                            id="view_2"
                            top={1}
                            left={6}
                            bottom={10}
                            right={10}
                            key="2"
                            dataProvisionContext={dataProvisionState.context}
                        />
                    </Grid>
                </div>
            )
        }
    }

    if (dataProvisionState.matches('error') && dataProvisionState.context.error) {
        components.push(
            <Dialog
                key={components.length + 1}
                title={intl.formatMessage({
                    defaultMessage: "An error occured.",
                    description: "A dialog title to inform the user that an error occured. Details are in the dialog"
                })}
                isOpen={true}
            >
                <div className={Classes.DIALOG_BODY}>
                    <p>{dataProvisionState.context.error.message}</p>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => dataProvisionSend('confirm_error')}>Confirm</Button>
                    </div>
                </div>
            </Dialog>
        )
    }

    return (<>
        {components}
    </>)
}