/**
 * @todo
 * - Put selected variant(s) into state of component
 * - Allow multiple selection
 * - Collect the variants with the last x% of cases into one variant
 * - Show if table has focus and allow variant navigation by keyboard
 * - Create a state machine for managing selected variants
 */

import { Column, Row, useTable } from 'react-table';
import { FormattedMessage, useIntl } from 'react-intl';
import { VariantsTableContext, variantsTableActor } from './VariantsTableStateMachine.js';

import { HTMLTable } from '@blueprintjs/core';
import React from 'react';
import TableScrollbar from './TableScrollbar.js';
import VariantsController from '../../classes/data_provision/VariantsController.js';

export interface VariantsTableProps {
    variants: VariantsController;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelect: (parameters: { caseIds: number[], controlId: string, controlState: any }) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerSelectionChange: (key: string, cb: (data: any) => void) => void;
}

interface Data {
    id: number;
    cases: number;
    percent: string;
    minLength: number;
    maxLength: number;
}

export function VariantsTable(props: VariantsTableProps) {

    /** init machine context, interpret machine and start service */
    const context: VariantsTableContext = {
        selectedVariantsIndexes: [],
        variants: props.variants.data,
        onSelect: props.onSelect
    }

    const intl = useIntl();

    const [state, setState] = React.useState(variantsTableActor.getSnapshot());
    React.useEffect(
        () => {
            props.registerSelectionChange("table", (data) => {
                variantsTableActor.send('change_selection', { data })
            })
            const subscription = variantsTableActor.subscribe(setState);
            variantsTableActor.send("init", { ctx:context })
            variantsTableActor.send("selectMostCommon");
            return () => {
                subscription.unsubscribe()
            }
        },
        [context.variants] // no dependencies, so this is called only once
    )
    console.log(`*** Render VariantsTable`);

    if (props.variants.data) {
        const createColumns: () => Column<Data>[] = () => {
            return [
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.nr",
                        defaultMessage: "No.",
                        description: "Label of for serial number of a variant."
                    }),
                    accessor: 'id'
                },
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.amount",
                        defaultMessage: "#",
                        description: "Label for amount of cases in a variant."
                    }),
                    accessor: 'cases'
                },
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.percent",
                        defaultMessage: "%",
                        description: "Label for % of total cases in this variant."
                    }),
                    accessor: 'percent'
                },
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.min_events",
                        defaultMessage: "min. # events",
                        description: "Label for minimal number of events in variant cases."
                    }),
                    accessor: 'minLength'
                },
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.max_events",
                        defaultMessage: "max. # events",
                        description: "Label for maximal number of events in variant cases."
                    }),
                    accessor: 'maxLength'
                },
            ]
        }

        const createTableData: () => Data[] = () => {
            const amountOfCases = props.variants.data.reduce((sum: number, v) => (sum += v.cases.length), 0);
            const data: Data[] = props.variants.data.slice(0, 200).map((v, i) => ({ /** @todo: make 200 max variant entries a configuration parameter */
                id: i + 1,
                cases: v.cases.length,
                percent: (Math.round(v.cases.length / amountOfCases * 10000) / 100).toFixed(2),
                minLength: v.minLength,
                maxLength: v.maxLength,
            }));
            return data;
        }

        const c = React.useMemo(createColumns, []);
        const d = React.useMemo(createTableData, [props.variants.data]);

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({ columns: c, data: d });

        const createRowProps = (tableRow: Row<Data>, idx: number) => {
            const result = {
                onClick: (event: React.MouseEvent<HTMLElement>) => {
                    variantsTableActor.send("clickRow", { rowIdx: tableRow.index, event });
                    event.preventDefault();
                    event.stopPropagation();
                    return;
                },
                style: {
                    cursor: "pointer"
                },
                className: ""
            }
            /** @todo: transfer the algorithm to detect if selected into the state machine source */
            if (state.context?.selectedVariantsIndexes.includes(idx)) {
                result.className = "selected"
            }
            return result;
        };

        return (
            <>
                <h2 className="bp5-heading">
                    <FormattedMessage
                        id="view.variants.ttl"
                        defaultMessage="{amount} flow variants"
                        description="Label with number of loaded variants."
                        values={{ amount: props.variants.data.length }}
                    />
                </h2>

                <TableScrollbar>
                    <HTMLTable {...getTableProps()} compact={true} bordered={true}>
                        <thead>
                            {headerGroups.map((headerGroup, j) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={"k" + j} >
                                    {headerGroup.headers.map((column, i) => (
                                        <th {...column.getHeaderProps()} key={"k" + i}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, row_idx) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps(createRowProps(row, row_idx))} key={"r" + row_idx}>
                                        {row.cells.map((cell, i) => {
                                            return (
                                                <td {...cell.getCellProps()} key={"c" + i}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </HTMLTable>
                </TableScrollbar>
            </>
        )
    }
    else {
        return <div>No variants data</div>
    }
}
