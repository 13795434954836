import React, { useState } from "react";
import { initRequests } from "../classes/Request.js";
import { Spinner } from "@blueprintjs/core";

export function Activity() {
    const [active,setActive] = useState(false);
    useState(()=>{
        initRequests(setActive);
    })
    return (
        <span>
            {active && <Spinner size={20} intent={"danger"}/>}
        </span>
    )
}