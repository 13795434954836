import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useForm, SubmitHandler } from "react-hook-form"

import {
    InputGroup,
    Button,
    FormGroup,
    Intent,
    H2,
} from '@blueprintjs/core';
import { AuthenticationEvent, authService } from './authentication/AuthenticationStateMachine.js';
import { InvalidInput } from '@insight/common/interface/authentication.js';
import { useActor } from '@xstate/react';

export interface MainEntranceProps {
    intl: IntlShape;
    send: (event: AuthenticationEvent) => void;
}

interface ILoginFormInput {
    userid: string;
    password: string;
}

export default function MainEntrance(props: MainEntranceProps) {

    const intl = useIntl();
    let invalidInput: InvalidInput = { _msg: "" };
    const [state, ] = useActor(authService);

    if (state.matches('rejected') && state.context.invalidInput) {
        invalidInput = state.context.invalidInput;
    }

    console.log('render Mainentrance');

    const { register, handleSubmit } = useForm<ILoginFormInput>();
    const onSubmit: SubmitHandler<ILoginFormInput> = values => {
        props.send({
            type: 'CREDENTIALS_PROVIDED',
            userid: values.userid,
            pw: values.password,
        });
    }

    /**
     * register of react-hook-form delivers a ref, but for blueprintjs's
     * FormGroup we need the ref prop renamed to inputRef
     * @param s
     * @returns
     */
    const registerWithInputRef = (s: "userid" | "password") => {
        const { onChange, onBlur, name, ref: inputRef } = register(s);
        return { onChange, onBlur, name, inputRef }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <H2>
                <FormattedMessage
                    id="entrance.title"
                    defaultMessage="Anmelden"
                    description="Title for login box."
                />
            </H2>
            <FormGroup
                helperText={invalidInput._msg ? invalidInput._msg : ""}
                intent={invalidInput._msg ? Intent.DANGER : Intent.NONE}
            >
            </FormGroup>

            <FormGroup
                helperText={invalidInput.userid ? invalidInput.userid._msg : ""}
                label={intl.formatMessage({
                    defaultMessage: "Emailaddress",
                    description: "Form label for email address field",
                })}
                intent={invalidInput.userid ? Intent.DANGER : Intent.NONE}
                labelFor="userid"
                labelInfo={intl.formatMessage({
                    defaultMessage: "(required)",
                    description: "Info message for email address field"
                })}
            >
                <InputGroup
                    {...registerWithInputRef("userid")}
                    id="userid"
                    name="userid"
                    type="userid"
                    placeholder={intl.formatMessage({
                        defaultMessage: "Enter your userid",
                        description: "Placecolder in input for userid"
                    })}
                    intent={invalidInput.userid ? Intent.DANGER : Intent.NONE}
                />
            </FormGroup>

            <FormGroup
                helperText={invalidInput.pw ? invalidInput.pw._msg : ""}
                label="Password"
                labelFor="password"
                labelInfo="(required)"
                intent={invalidInput.pw ? Intent.DANGER : Intent.NONE}
            >
                <InputGroup
                    {...registerWithInputRef("password")}
                    id="password"
                    name="password"
                    type="text"
                    // onBlur={formik.handleChange}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Enter your password",
                        description: "Placecolder in input for password"
                    })}
                    intent={invalidInput.pw ? Intent.DANGER : Intent.NONE}
                />
            </FormGroup>
            <Button type="submit">Submit</Button>
        </form>
    )
}
