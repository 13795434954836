import React from "react";
import { IntlShape, injectIntl } from "react-intl";

import InteractiveGraph from "../interactive_graph/InteractiveGraph.js";
import { DataProvisionContext } from "../../classes/data_provision/DataProvider.js";
import { GridGroup, GridCell, IGridElementProps } from "../Grid/Grid.js";
import { VariantsTable } from "../VariantsTable/VariantsTable.js";
import { TimeChart } from "../TimeChart.js";
import { dataSelectActor, CaseSelection, DataSelectionInterpreterType, DataSelectionStateSchema, DataSelectionContext } from "./DataSelectionStateMachine.js";
import { FilterManager } from "../CaseSelection/FilterManager.js";
import { DotGraph } from "@insight/common/dot_graph/dotgraph.js";
import { State, EventObject, ResolveTypegenMeta, BaseActionObject } from "xstate";
import { Typegen0 } from "./DataSelectionStateMachine.typegen.js";

export interface ViewProps extends IGridElementProps {
    intl: IntlShape;
    dataProvisionContext: DataProvisionContext;
}

/**
 * Integrates the graph, chart and case selection components in the UI and manages
 * the case selection.
 */
class View extends React.Component<ViewProps, DataSelectionStateSchema> {

    /** A state machine to control variants selections and case filtering */
    // dataSelectService: DataSelectionInterpreterType;

    state: DataSelectionStateSchema;

    /**
     *
     * @param props ViewProps
     */
    constructor(props: ViewProps) {
        console.log(`--- View INIT`)
        super(props);

        // this.dataSelectService = DataSelectionStateMachine
        //     props
        //         .dataProvisionContext
        //         .dataControllers
        //         .graphController
        //         .data);

        /**
         * @todo getting initial state and starting the interpreter both
         * trigger a xstate.init event
         **/
        // this.state = this.dataSelectService.machine.initialState;
        // this.dataSelectService.start();
        this.state = dataSelectActor.getSnapshot()
    }

    componentDidUpdate(prevProps: Readonly<ViewProps>, prevState: Readonly<State<DataSelectionContext, (EventObject & { type: "select_cases"; } & CaseSelection) | (EventObject & { type: "back"; }) | (EventObject & { type: "fwd"; }) | (EventObject & { type: "xstate.init"; }) | (EventObject & { type: "register"; controlId: string; selectionChangeCallback: (data: unknown) => void; }) | (EventObject & { type: "update_graph"; graph: DotGraph; }), any, { value: any; context: DataSelectionContext; }, ResolveTypegenMeta<Typegen0, (EventObject & { type: "select_cases"; } & CaseSelection) | (EventObject & { type: "back"; }) | (EventObject & { type: "fwd"; }) | (EventObject & { type: "xstate.init"; }) | (EventObject & { type: "register"; controlId: string; selectionChangeCallback: (data: unknown) => void; }) | (EventObject & { type: "update_graph"; graph: DotGraph; }), BaseActionObject, { selectCases: { data: { filteredGraph: DotGraph; selection: CaseSelection; }; }; }>>>, snapshot?: any): void {
        if (prevProps !== this.props) {
            dataSelectActor.send('update_graph', { graph: this.props.dataProvisionContext.dataControllers.graphController.data });
        }
    }

    componentDidMount = () => {
        dataSelectActor.onTransition((state) => {
            if (state._event.name !== "register" || this.state.value !== state.value) {
                console.log(`+++ View: ${state._event.name} ${state.value}`);
                return this.setState(state);
            }
        });
    };

    componentWillUnmount = () => {
        /** stop the state machine service */
        // this.dataSelectService.stop();
    };

    selectCases = (selection: CaseSelection) => {
        dataSelectActor.send("select_cases", selection);
    };

    invertCaseSelection = () => {
        const context = this.state.context; // variantsGraph and variantsTable data are local to this component
        if (context.filteredGraph !== null) {
            let caseIds = context.graph.casesInfo.map(c => c.id);
            const currentSelection = context.filteredGraph.casesInfo.map(c => c.id);
            caseIds = caseIds.filter(c => !currentSelection.includes(c));
            this.selectCases({ caseIds, controlId: "interactive_graph", controlState: null });
        }
    }

    goBack = () => {
        dataSelectActor.send("back");
    };

    goFwd = () => {
        dataSelectActor.send("fwd");
    };

    registerSelectionChange = (controlId: string, selectionChangeCallback: (filterIds: number[]) => void) => {
        dataSelectActor.send("register", { controlId, selectionChangeCallback })
    }

    render() {
        console.log("*** Render View");
        const context = this.state.context;
        if (context && this.props.dataProvisionContext.project) {
            let timeChartGridContent;
            let processGridContent;
            if (context.filteredGraph === null) {
                timeChartGridContent = null;
                processGridContent = null;
            }
            else {
                timeChartGridContent = <TimeChart
                    project={this.props.dataProvisionContext.project}
                    graph={context.filteredGraph}
                    onSelect={this.selectCases}
                />;
                processGridContent = <InteractiveGraph
                    project={this.props.dataProvisionContext.project}
                    graph={context.filteredGraph}
                    onBack={this.goBack}
                    onFwd={this.goFwd}
                    backPossible={context.stackPtr > -1}
                    fwdPossible={
                        context.stack !== undefined &&
                        context.stackPtr < context.stack.length - 1
                    }
                    onSelect={this.selectCases}
                    invertCaseSelection={this.invertCaseSelection}
                />
            }

            return (
                /**
                 * @todo: change id generation in Grid to something better. If Grid is not re-rendered
                 *  and children are rendered, the ids are chaning. Helping here by setting the id
                 *  manually.
                 */
                <>
                    <GridGroup {...this.props} >
                        <GridCell
                            id="tree"
                            top={1}
                            left={1}
                            bottom={5}
                            right={1}
                            className="tree"
                        >
                            <FilterManager
                                caseFiltersController={this.props.dataProvisionContext.dataControllers.caseFiltersController}
                                onSelect={this.selectCases}
                                registerSelectionChange={this.registerSelectionChange}
                                graph={context.graph}
                                project={this.props.dataProvisionContext.project}
                            />
                        </GridCell>
                        <GridCell
                            id="selector"
                            top={6}
                            left={1}
                            bottom={10}
                            right={1}
                            className="selector"
                        >
                            <VariantsTable
                                variants={this.props.dataProvisionContext.dataControllers.variantsController}
                                onSelect={this.selectCases}
                                registerSelectionChange={this.registerSelectionChange}
                            />
                        </GridCell>
                        <GridCell
                            id="graph"
                            top={1}
                            left={2}
                            bottom={2}
                            right={5}
                            className="graph"
                        >
                            {timeChartGridContent}
                        </GridCell>
                        <GridCell
                            id="process"
                            top={3}
                            left={2}
                            bottom={10}
                            right={5}
                            className="process"
                        >
                            {processGridContent}
                        </GridCell>
                    </GridGroup>
                </>
            );
        } else {
            return <>No data available</>;
        }
    }
}

// inject intl from IntlProvider in App component
// see https://formatjs.io/docs/react-intl/api/#injectintl-hoc
const wrappedView = injectIntl<"intl", ViewProps>(View);
export { wrappedView as View }; // inject 'intl' prop into component
