import React, { useState } from 'react';
import * as Flags from 'country-flag-icons/react/3x2';
import { useIntl } from 'react-intl';

import { useActor } from '@xstate/react';
import log from "./Logger.js";

import {
    Alignment,
    Button,
    Classes,
    Drawer,
    Icon,
    Menu,
    MenuDivider,
    MenuItem,
    Navbar,
    NavbarGroup,
    Popover,
    Position
} from '@blueprintjs/core';

import { AuthProvider, Protector } from './authentication/AuthenticationProvider.js';
import Homepage, { HomePageProps } from './Homepage.js';
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";

import CaseFiltersController from '../classes/data_provision/CaseFiltersController.js';
import GraphController from '../classes/data_provision/GraphController.js';
import VariantsController from '../classes/data_provision/VariantsController.js';

import Close from './Close.js';
import DataProvider from '../classes/data_provision/DataProvider.js';
import { DragDropFile } from './DragDropFile/DragDropFile.js';
import MainEntrance from './MainEntrance.js';
import { Menuframe } from './Menuframe.js';
import { ThemeProvider } from "styled-components";
import { authService } from './authentication/AuthenticationStateMachine.js';
import displayModeService from '../classes/displayModeService.js';
import { localizationService } from '../classes/LocaleStateMachine.js';
import { sendRequest } from '../classes/Request.js';
import socketService from '../classes/socketService.js';
import { DatasetSelectionDialog } from './DatasetSelectionDialog/DatasetSelectionDialog.js';
import Project from '@insight/common/interface/Project.js';
import { Activity } from './Activity.js';

import { CaseFilterRunTime } from '../classes/case_filter/CFRunTime.js';
import { CaseFilterEventCount } from '../classes/case_filter/CFEventCount.js';
import { SetProgressContext } from './ProgressOverlay.js';

/* eslint-disable @typescript-eslint/no-unused-vars */
CaseFilterRunTime;
CaseFilterEventCount;
/* eslint-enable @typescript-eslint/no-unused-vars */


/**
 * intermediate fixed definition of the project.
 */
const dataProvider = new DataProvider(
    {
        graphController: new GraphController(),
        variantsController: new VariantsController(),
        caseFiltersController: new CaseFiltersController({ saveable: true })
    }
);

export interface BaseplateProperties {
    pushMenu: boolean;
}

export default function Baseplate(props: BaseplateProperties) {

    log.debug(`*** *** Render Baseplate`);

    const [drawerOpen, setDrawer] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [locale_state, send] = useActor(localizationService);
    const [socketState] = useActor(socketService);

    const locale = locale_state.context.locale;

    const toggleDrawer = () => {
        setDrawer(!drawerOpen);
    }

    const closeDrawer = () => {
        setDrawer(false);
    }

    const theme = {
        color: '#FFFFFF',
        disabledColor: '#909090',
        backGroundColor: "#242424",
        borderColor: '#D0D0D0',
    };

    const rtl = false;
    const intl = useIntl();

    const localeFlags = {
        'de': <Flags.DE className="flag-icon" />,
        'en': <Flags.GB className="flag-icon" />,
    }

    const langItems = Object.keys(localeFlags).map((locale) => {
        return (
            <MenuItem text="" key={locale} icon={localeFlags[locale as 'de' | 'en']} onClick={() => {
                send({ type: 'select', locale })
            }} />
        )
    })

    const toggleDisplay = () => {
        displayModeService.send('TOGGLE');
    }

    const closeServer = () => {
        sendRequest("/close", {
            method: "post",
        }).catch((err) => {
            log.error(err); /** @tody: improve error handling */
        })
    }

    const logout = () => {
        authService.send('LOG_OUT');
    }

    const progressOverlay = React.useContext(SetProgressContext);
    const handleSelectDataset = (p: Project) => {
        setDialogOpen(false);
        dataProvider.service.send('load', { project: p, setProgress: progressOverlay?.setProgress });
    }

    return (
        <ThemeProvider theme={theme}>
            {dialogOpen && <DatasetSelectionDialog
                onClose={() => setDialogOpen(false)}
                onSelectDataset={handleSelectDataset}
            />}
            <Menuframe id="app" className={`${rtl ? 'rtl' : ''} ${drawerOpen ? 'menu-open' : ''} ${props.pushMenu ? 'push-menu' : ''}`}>
                <Navbar>
                    <NavbarGroup align={Alignment.LEFT}>
                        <Button
                            className={Classes.MINIMAL}
                            icon="menu"
                            onClick={toggleDrawer}
                        />
                        <Button
                            disabled={progressOverlay === null || socketState.matches('disconnected')}
                            className={Classes.MINIMAL}
                            onClick={() => setDialogOpen(true)}
                            icon="document-open"
                            text={intl.formatMessage({
                                defaultMessage: "Load Processes",
                                description: "Menu action label to load processes from server."
                            })}
                        />
                        <Button
                            className={Classes.MINIMAL}
                            onClick={toggleDisplay}
                            icon="modal"
                            text={intl.formatMessage({
                                defaultMessage: "Toggle single/dual view",
                                description: "Menu action label to toggle single/dual view.",
                            })}
                        />
                        {/* <Button
                            className={Classes.MINIMAL}
                            onClick={closeServer}
                            icon="power"
                            text={intl.formatMessage({
                                defaultMessage: "Close Server",
                                description: "Menu action to shut down view server."
                            })}
                            disabled={socketState.matches('disconnected')}
                        /> */}
                    </NavbarGroup>
                    <NavbarGroup className="bp5-align-center app-title">
                        InSight V1.5
                    </NavbarGroup>

                    <NavbarGroup align={Alignment.RIGHT}>
                        <Activity />
                        <Button
                            className={Classes.MINIMAL}
                            onClick={logout}
                            icon="log-out"
                            text={intl.formatMessage({
                                defaultMessage: "Logout",
                                description: "Menu action label to log out the user from the server."
                            })}
                        />
                        <Popover
                            content={<Menu>{langItems}</Menu>} minimal={true} position={Position.TOP_LEFT}>
                            <Button
                                className={Classes.MINIMAL}
                            >
                                <Icon icon="translate" />
                                <Icon icon={localeFlags[locale]} />
                            </Button>
                        </Popover>
                    </NavbarGroup>
                </Navbar>
                <Drawer
                    title="My Drawer"
                    hasBackdrop={!props.pushMenu}
                    isOpen={drawerOpen}
                    onClose={closeDrawer}
                    canOutsideClickClose={false}
                    position={Position.LEFT}
                    enforceFocus={!props.pushMenu}
                    usePortal={false}
                    canEscapeKeyClose={!props.pushMenu}
                    transitionDuration={500}
                >
                    <DragDropFile />
                    <Menu className={Classes.ELEVATION_3}>
                        <MenuDivider title="Edit" />
                        <MenuItem icon="cut" text="Cut" label="⌘X" />
                        <MenuItem icon="duplicate" text={intl.formatMessage({ defaultMessage: "Copy", description: "Menu action to copy a value to the clipboard" })} label="⌘C" />
                        <MenuItem icon="clipboard" text={intl.formatMessage({ defaultMessage: "Paste", description: "Menu action label to insert a value from the clipboard to the applicatoin" })} label="⌘V" disabled={true} />
                        <MenuDivider title="Text" />
                        <MenuItem disabled={false} icon="align-left" text="Alignment">
                            <MenuItem icon="align-left" text="Left" />
                            <MenuItem icon="align-center" text="Center" />
                            <MenuItem icon="align-right" text="Right" />
                            <MenuItem icon="align-justify" text="Justify" />
                        </MenuItem>
                        <MenuItem icon="style" text="Style">
                            <MenuItem icon="bold" text="Bold" />
                            <MenuItem icon="italic" text="Italic" />
                            <MenuItem icon="underline" text="Underline" />
                        </MenuItem>
                        <MenuItem icon="asterisk" text="Miscellaneous">
                            <MenuItem icon="badge" text="Badge" />
                            <MenuItem icon="book" text="Long items will truncate when they reach max-width" />
                            <MenuItem icon="more" text="Look in here for even more items">
                                <MenuItem icon="briefcase" text="Briefcase" />
                                <MenuItem icon="calculator" text="Calculator" />
                                <MenuItem icon="dollar" text="Dollar" />
                                <MenuItem icon="dot" text="Shapes">
                                    <MenuItem icon="full-circle" text="Full circle" />
                                    <MenuItem icon="heart" text="Heart" />
                                    <MenuItem icon="ring" text="Ring" />
                                    <MenuItem icon="square" text="Square" />
                                </MenuItem>
                            </MenuItem>
                        </MenuItem>
                    </Menu>
                </Drawer>
                <AuthProvider loginComponent={MainEntrance}>
                    <Router >
                        <Routes>
                            <Route path="/close" element={<Close intl={intl} />} />
                            <Route path="/" element={<Protector<HomePageProps> component={Homepage} props={{ intl, dataProvider }} />} />
                            <Route element={<Navigate to="/" />} />
                        </Routes>
                    </Router>
                </AuthProvider>
            </Menuframe>
        </ThemeProvider>
    );

}
