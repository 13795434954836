import React, { useEffect } from 'react';
import { Tree, TreeEventHandler, TreeNodeInfo } from '@blueprintjs/core';
import { DragPositions, DropHandler, SelectTreeLabel } from './SelectTreeLabel.js';
import Project from '@insight/common/interface/Project.js';
import { CaseFilter } from '../../classes/case_filter/CaseFilter.js';

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }
type CaseFilterTreeNodeInfo = WithRequired<TreeNodeInfo<CaseFilter>, "nodeData">;

/**
 * For Drag & Drop implemententation, basically following https://web.dev/articles/drag-and-drop?hl=en, but in
 * React-language
 */
export interface SelectTreeProps {
    caseFilters: CaseFilter[];
    handleDrop: (item: CaseFilter, position: DragPositions, target: CaseFilter) => void,
    selectFiltersIds: (ids: number[]) => void,
    selectedFiltersIds: number[],
    project: Project;
}

export type NodePath = number[];

export function SelectTree(props: SelectTreeProps) {

    function createTreeNodeInfo(filter: CaseFilter) {
        let tni: CaseFilterTreeNodeInfo = {
            id: filter.id,
            label: <SelectTreeLabel filter={filter} handleDrop={props.handleDrop} />,
            isSelected: props.selectedFiltersIds.includes(filter.id),
            nodeData: filter
        };
        if (filter.children.length > 0) {
            tni = {
                ...tni,
                isExpanded: expandedFiltersIds.includes(filter.id),
                hasCaret: true,
                childNodes: nodeList(filter.children, props.handleDrop),
            }
        }
        return tni;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function nodeList(filters: CaseFilter[], _handleDrop: DropHandler): CaseFilterTreeNodeInfo[] {
        const result = filters.map<CaseFilterTreeNodeInfo>(filter => {
            return createTreeNodeInfo(filter);
        });
        return result;
    }

    const [expandedFiltersIds, setExpandedFiltersIds] = React.useState<number[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleNodeCollapse = React.useCallback((node: CaseFilterTreeNodeInfo, _nodePath: NodePath) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setExpandedFiltersIds(expandedFiltersIds.filter(id => id !== node.nodeData!.id))
    }, [expandedFiltersIds]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleNodeExpand = React.useCallback((_node: CaseFilterTreeNodeInfo, _nodePath: NodePath) => {
        if (_node.nodeData !== undefined) {
            setExpandedFiltersIds([...expandedFiltersIds, _node.nodeData.id])
        }
    }, [expandedFiltersIds]);

    const handleClick = React.useCallback((_node: CaseFilterTreeNodeInfo, _nodePath: NodePath, e: React.MouseEvent<HTMLElement>) => {
        const target: Element = e.target as Element;
        if (target.closest('.bp5-context-menu') !== null) {
            if (!e.ctrlKey) {
                props.selectFiltersIds(props.selectedFiltersIds.includes(_node.nodeData?.id) ? [] : [_node.nodeData.id])
            }
            else if (props.selectedFiltersIds.includes(_node.nodeData.id)) {
                props.selectFiltersIds(props.selectedFiltersIds.filter(id => id !== _node.nodeData.id))
            }
            else {
                props.selectFiltersIds([...props.selectedFiltersIds, _node.nodeData.id])
            }
        }
    }, [props.selectedFiltersIds]);

    useEffect(() => {
        console.log("*** render SelectTree");
    });

    const nodes = React.useMemo(() => {
        return nodeList(props.caseFilters, props.handleDrop)
    }, [props.caseFilters, props.selectedFiltersIds, expandedFiltersIds]);

    const result = <Tree<CaseFilter>
        contents={nodes}
        onNodeExpand={handleNodeExpand as TreeEventHandler<CaseFilter>}
        onNodeCollapse={handleNodeCollapse as TreeEventHandler<CaseFilter>}
        onNodeClick={handleClick as TreeEventHandler<CaseFilter>}
    />
    console.log('*** SelectTree ready')
    return result;
}