import { RunTime } from './run_time.js';
/**
 * An EdgeCase is a case which runs 1 ... n times along an edge,
 * each time with a different runtime
 */
export class EdgeCase {
    id: number;
    runtimes: Array<RunTime>;
    count: number;
    __runtime: number;

    constructor(id: number, rt: Array<RunTime>) {
        this.id = id;
        this.runtimes = rt;
        this.count = 0;
        this.__runtime = 0;
    }

    get runtime() {
        const len = this.runtimes.length;
        /** recalculate runtime if length of array was changed */
        if (this.count !== len) {
            this.count = len;
            this.__runtime = this.runtimes.reduce((crt, rt) => crt + rt.ms, 0);
        }
        return this.__runtime;
    }
}
