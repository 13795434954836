import {
    createMachine,
    interpret,
    assign,
    EventObject,
    StateFrom,
    InterpreterFrom,
} from "xstate";

import { DotGraph } from "@insight/common/dot_graph/dotgraph.js";

interface HistoryStackItem {
    filteredGraph: DotGraph;
    controlStates: { [controlId: string]: unknown }; // state data for each case selection control
}

/**
 * An array of case ids and the id and state of the control, doing the selection.
 */
export interface CaseSelection {
    caseIds: number[]; // the selected cases
    controlId: string; // the case selection component which made the selection
    controlState: unknown; // the state of the case selection component
}

export interface DataSelectionContext {
    stack: HistoryStackItem[]; // the stack of previous states
    stackPtr: number; // the stack pointer, always points to the current state. if -1, all cases are selected
    graph: DotGraph;  // the original process graph
    filteredGraph: DotGraph | null; // the filtered graph
    controlStates: { [controlId: string]: unknown };  // the states of the controls that selected the cases.
    selectionChangeCallbacks: { [controlId: string]: (data: unknown) => void }; // the callback to the case selecting controls when the user moves back and forth in the history
}

type PartialDataSelectionContext = Partial<DataSelectionContext>;

export type ViewEvent = EventObject &
    (
        | ({ type: "select_cases"; } & CaseSelection)
        | { type: "back" }
        | { type: "fwd" }
        | { type: "xstate.init" }
        | { type: "register"; controlId: string, selectionChangeCallback: (data: unknown | undefined) => void }
        | { type: "update_graph"; graph: DotGraph }
    );
/**
 *
 * @param updatedControlId The id of the case selection component that performed the selection change
 * @param context The current data selection context
 * @param extState The history stack item that will be used
 */
function performSelectionChangeCallbacks(updatedControlId: string, context: DataSelectionContext, extState: HistoryStackItem) {
    for (const controlId in context.selectionChangeCallbacks) {
        // no need to feed back selection change to the control which did the change
        if (controlId !== updatedControlId) {
            context.selectionChangeCallbacks[controlId](extState.controlStates[controlId]);
        }
    }
}

/**
 * A state machine to register case selection controls, to select cases provided by those and to
 * maintain a history stack of selected cases and move forth and back in that history.
 */
const machine = createMachine(
    {
        predictableActionArguments: true,
        id: "dataSelectMachine",
        tsTypes: {} as import("./DataSelectionStateMachine.typegen.d.ts").Typegen0,
        schema: {
            context: {} as DataSelectionContext,
            events: {} as ViewEvent,
            services: {} as {
                selectCases: {
                    data: {
                        filteredGraph: DotGraph,
                        selection: CaseSelection,
                    }
                },
            },
        },

        /** initial state*/
        initial: "idle",

        /** top level event handling */
        on: {
            /**
             * register case selection change callback. The selection change callback will be called
             * whenever the selection changes.
             */
            register: {
                actions: assign((context, event) => {
                    const selectionChangeCallbacks = context.selectionChangeCallbacks;
                    selectionChangeCallbacks[event.controlId] = event.selectionChangeCallback;
                    return { selectionChangeCallbacks: selectionChangeCallbacks };
                })
            },
        },
        context: {
            stack: [],
            stackPtr: 0,
            filteredGraph: null,
            graph: new DotGraph(),
            controlStates: {},
            selectionChangeCallbacks: {}
        },

        /* state level event handling */
        states: {
            idle: {
                on: {
                    select_cases: {
                        actions: [
                            "selectCases", // select the cases determined by the control
                            "push_state", // push new data on history stack - we actually don't combine selections from different controls (yet)
                        ],
                        // target: "idle"
                    },
                    back: [
                        {
                            /* go back one step, if an item is left on steck */
                            // target: "idle",
                            cond: (context: DataSelectionContext) => {
                                return context.stackPtr >= 0;
                            },
                            actions: "back_state",
                        },
                        {
                            /* clear stack and select all, if only current state on stack */
                            target: "idle",
                            actions: ["clear_stack"],
                        },
                    ],
                    fwd: {
                        // target: "idle",
                        cond: (context: DataSelectionContext) => {
                            return context.stack && context.stackPtr < context.stack.length - 1;
                        },
                        actions: "fwd_state",
                    },
                    update_graph: {
                        actions: assign((context, event) => {
                            return {
                                stack: [], // the stack of previous states
                                stackPtr: -1, // the stack pointer, always points to the current state. if -1, all cases are selected
                                graph:event.graph,
                                filteredGraph: null,
                                controlStates: {},
                                // selectionChangeCallbacks: {},
                            }
                        })
                    }
                },
            },
        },
    },
    {
        actions: {
            selectCases: assign((context: DataSelectionContext, event): PartialDataSelectionContext => {
                const filteredGraph = context.graph.copy();
                if (event.caseIds.length > 0) filteredGraph.filterToCases(event.caseIds);
                const result: PartialDataSelectionContext = {
                    filteredGraph,
                    controlStates: { [event.controlId]: event.controlState },
                }
                return result; // update context with filtered graph and control's state
            }),

            /**
             * push current state to stack. cut stack, if current pointer is somewhere in the
             * middle of the stack. as a result, the current status is always on the top
             * of the stack.
             */
            push_state: assign((context: DataSelectionContext, event): PartialDataSelectionContext => {
                if (context.filteredGraph !== null) {

                    const controlStates = { ...context.controlStates };
                    if (event.controlId && event.controlState !== undefined) {
                        controlStates[event.controlId] = event.controlState;
                    }

                    const extState: HistoryStackItem = {
                        filteredGraph: context.filteredGraph,
                        controlStates: controlStates,
                    };

                    let stack = context.stack;
                    if (stack === undefined || context.stackPtr === -1) {
                        /* nothing on stack, init it */
                        return { stack: [extState], stackPtr: 0, controlStates: controlStates };
                    } else {
                        if (context.stackPtr === context.stack.length - 1) {
                            /* at end of stack, limit size to 5 */
                            if (stack.length >= 5) {
                                stack.shift();
                            }
                        } else {
                            /* somewhere in stack, remove old states above the pointer */
                            stack = stack.slice(0, context.stackPtr + 1);
                        }
                        stack.push(extState);
                        performSelectionChangeCallbacks(event.controlId, context, extState);

                        return { stack, stackPtr: stack.length - 1, controlStates };
                    }
                }
                else {
                    return {};
                }
            }),

            /**
             * go back one step. pointer has been checked to be > 0 in
             * condition for this action
             */
            back_state: assign((context: DataSelectionContext): PartialDataSelectionContext => {
                context.stackPtr--;
                let extState: HistoryStackItem;
                if (context.stackPtr >= 0) {
                    extState = context.stack[context.stackPtr];
                }
                else {
                    extState = {
                        filteredGraph: context.graph,
                        controlStates: {}
                    }
                }
                performSelectionChangeCallbacks("", context, extState);
                return extState;
            }),

            /**
             * go forward one step. pointer has been checked to be
             * below end of stack by condition for this action
             */
            fwd_state: assign((context: DataSelectionContext): PartialDataSelectionContext => {
                context.stackPtr++;
                const extState = context.stack[context.stackPtr];
                performSelectionChangeCallbacks("", context, extState);
                return extState;
            }),

            /**
             * clear stack = set stack pointer to -1, content
             * remains on stack.
             */
            clear_stack: assign(() => {
                return { stackPtr: -1 };
            }),
        },
    }
);

export type DataSelectionInterpreterType = InterpreterFrom<typeof machine>;
export type DataSelectionStateSchema = StateFrom<typeof machine>;

// let result: DataSelectionInterpreterType;

/**
 * Returns a running state machine to register case selection controls, to select cases provided by those and to
 * maintain a history stack of selected cases and move forth and back in that history.
 *
 * @param graph
 * @returns The state machine
 */
export const dataSelectActor = interpret(machine).start();
// export function getDataSelectService(graph: DotGraph) {
//     result = interpret(machine.withContext({
//         stack: [], // the stack of previous states
//         stackPtr: -1, // the stack pointer, always points to the current state. if -1, all cases are selected
//         graph,
//         filteredGraph: null,
//         controlStates: {},
//         selectionChangeCallbacks: {},
//     }));
//     return result;
// }