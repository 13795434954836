import React from "react";
import { IntlProvider } from "react-intl";
import log from "./components/Logger.js";

import "@blueprintjs/core/lib/css/blueprint.css";
import "./assets/scss/App.scss";

import Baseplate from './components/Baseplate.js';
import * as RequestController from "./classes/Request.js";
import { useActor } from "@xstate/react";
import { messages, localizationService } from "./classes/LocaleStateMachine.js";
import ProgressBarOverlay, { OverlayProvider } from "./components/ProgressOverlay.js";

export default function App() {

    log.setLevel(log.levels.TRACE);

    const [state] = useActor(localizationService);
    const locale = state.context.locale;

    RequestController.removeRequestModificator("app");
    RequestController.setRequestModificator("app", (request) => {
        const langs: string[] = [locale];
        let q = 1.0;
        Object.keys(messages).forEach((lang) => {
            if (lang !== locale) {
                q -= 0.05;
                langs.push(`${lang};q=${q}`);
            }
        });
        const acceptedLanguages = langs.join(", ");
        request.headers = { ...request.headers, "Accept-Language": acceptedLanguages };
    });

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <OverlayProvider>
                <ProgressBarOverlay />
                {/* <div> */}
                <Baseplate pushMenu={true} />
                {/* </div> */}
            </OverlayProvider>
        </IntlProvider>
    );
}
