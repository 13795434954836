import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartDataset } from 'chart.js'
import { IntlShape, injectIntl } from 'react-intl';
import { DotGraph } from "@insight/common/dot_graph/dotgraph.js";

import {
    Chart as ChartJS,
    ActiveElement,
    ChartEvent,
    Filler,
    CategoryScale,
    LogarithmicScale,
    LinearScale,
    LineController,
    PointElement,
    LineElement,
    BarElement,
} from 'chart.js'
ChartJS.register(LogarithmicScale, CategoryScale, LinearScale, PointElement, LineController, LineElement, BarElement, Filler);

import {
    Button,
    Checkbox,
    MenuItem,
} from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";

import {
    createMachine,
    interpret,
    assign,
    InterpreterFrom,
    StateFrom,
} from 'xstate';

import ChartDataStore, {
    ChartType,
    isChartDataStore,
    MinMax,
    PartialTimeChartOptions,
    TimeChartOptions
} from "@insight/common/interface/iChart.js";

import { sendRequest } from '../classes/Request.js';
import Project from '@insight/common/interface/Project.js';
import { CaseSelection } from './View/DataSelectionStateMachine.js';

const max = 150;
const numTickIncrements = [0.5, 1, 2, 2.5]; /* possible Tick increments on a scale of 0-10 */
const MAX_TICKS = 8;

const SEC = 1 * 1000;
const MIN = 60 * SEC;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 365 * DAY / 12;
const YEAR = 365 * DAY;
const DECADE = 10 * YEAR;

const axis1Color = `#E6710D`;
const axis1Background = axis1Color + "40";
const axis2Color = `#0B91DA`;
const selectColor = `#D70022`;

const timeMagnitudeTickIntervalss = [
    { mag: SEC, timeSpanTicks: [1, 2, 5, 10, 15, 20, 30] }, // sec
    { mag: MIN, timeSpanTicks: [1, 2, 5, 10, 15, 20, 30] },// min
    { mag: HOUR, timeSpanTicks: [1, 2, 3, 6, 12] }, // hour
    { mag: DAY, timeSpanTicks: [1, 7] },// day
    { mag: WEEK, timeSpanTicks: [1, 2, 4] }, // week
    { mag: MONTH, timeSpanTicks: [1, 3, 6] }, // months
    { mag: YEAR, timeSpanTicks: [1, 2, 5, 10] }, // years
    { mag: DECADE, timeSpanTicks: [1, 2, 5, 10] }, // decades
];

const defaultChartType: ChartType = "START_INTERVAL";

export interface TimeChartProps {
    onSelect: (selectedCases: CaseSelection) => void;
    graph: DotGraph;
    intl: IntlShape;
    project: Project;
}

interface IntervalSelectorContext {
    selection: number[];
}

export type IntervalSelectionEvent =
    | { type: "ctrl_select", timeIntervalIndex: number }
    | { type: "range_select"; timeIntervalIndex: number }
    | { type: "reset" }

interface TimeChartState {
    displayOptions: boolean;
    selection: number[];
    chartData: ChartDataStore | null;
    chartOptions: TimeChartOptions;
}

const getChartData = (
    eventsfile: string,
    caseIds: number[],
    options: { type?: string } = {}
): Promise<ChartDataStore | null> => {
    let url;
    const caseIdData: (number[] | number)[] = [];
    const query = new URLSearchParams({
        eventsfile,
    });

    switch (options.type) {
        case "RUNTIME_DIST":
            url = "/data/runtime?" + query.toString();
            break;

        default:
            url = "/data/chart?" + query.toString();
            break;
    }

    /**
     * convert list of case ids into a list of intervals and single case numbers to
     * shorten the amount of data needed to be transferred to the server. In the case
     * of thousands of cases, it would otherwise create a HTTP 413 error.
     */
    let interval: number[] = [];
    caseIds.forEach(caseId => {
        if (interval.length === 0) {
            interval.push(caseId);
        }
        else if (interval.length === 1) {
            if (caseId === interval[0] + 1) {
                interval.push(caseId);
            }
            else {
                caseIdData.push(interval[0]);
                interval[0] = caseId;
            }
        }
        else if (interval.length === 2) {
            if (caseId === interval[1] + 1) {
                interval[1] = caseId;
            }
            else {
                if (interval[1] === interval[0] + 1) {
                    caseIdData.push(interval[0]);
                    caseIdData.push(interval[1]);
                }
                else {
                    caseIdData.push(interval);
                }
                interval = [caseId];
            }
        }
    })
    if (interval.length === 1) {
        caseIdData.push(interval[0]);
    }
    else {
        caseIdData.push(interval);
    }

    return sendRequest(url, {
        method: "post",
        data: { caseIdData, options },
    })
        .then((data) => {
            if (!isChartDataStore(data)) {
                // throw new Error("Expected a ChartDataStore structure.");
                return null;
            }
            else {
                return data;
            }
        });
};


class TimeChart extends React.Component<TimeChartProps, TimeChartState> {
    timeIntervalSelectorMachine = createMachine(
        {
            predictableActionArguments: true,
            id: 'timeIntervalSelector',
            tsTypes: {} as import("./TimeChart.typegen.d.ts").Typegen0,
            schema: {
                context: {} as IntervalSelectorContext,
                events: {} as IntervalSelectionEvent,
            },
            initial: 'no_selection',
            context: {
                selection: [],
            },
            states: {
                no_selection: {
                    entry: 'clear_selection',
                    on: {
                        ctrl_select: {
                            target: 'set_selection',
                            actions: "select_first_time_interval"
                        },
                        range_select: {
                            target: 'range_selection',
                            actions: "select_first_time_interval"
                        }
                    }
                },
                set_selection: {
                    on: {
                        ctrl_select: {
                            target: 'set_selection',
                            actions: "select_another_time_interval"
                        },
                        range_select: {
                            target: 'select_time_intervals',
                            actions: "select_another_time_interval",
                        }
                    }
                },
                range_selection: {
                    on: {
                        range_select: {
                            target: 'select_time_intervals',
                            actions: "select_time_interval_range",
                        }
                    }
                },
                select_time_intervals: {
                    on: {
                        reset: 'no_selection'
                    }
                }
            },
        },
        {
            actions: {
                /**
                 * clear selection
                 */
                clear_selection: assign(() => {
                    return { selection: undefined };
                }),

                /**
                 * selects first time interval
                 */
                select_first_time_interval: assign((context, event) => {
                    const selection = [event.timeIntervalIndex];
                    return { selection }
                }),

                /**
                 * selects another time interval
                 */
                select_another_time_interval: assign((context, event) => {
                    return { selection: [...context.selection, event.timeIntervalIndex] };
                }),

                /**
                 * selects a time interval range
                 */
                select_time_interval_range: assign((context, event) => {
                    let first = context.selection[0];
                    let last = event.timeIntervalIndex;
                    if (first > last) {
                        const tmp = last;
                        last = first;
                        first = tmp;
                    }
                    const selection = [];
                    for (let idx = first; idx <= last; idx++) selection.push(idx);
                    return { selection }
                }),
            }
        }
    );
    timeIntervalSelector: InterpreterFrom<typeof this.timeIntervalSelectorMachine>;

    constructor(props: TimeChartProps) {
        super(props);
        /**
         * a state machine to control the selection state
         * of time / data intervals in the displayed chart
         * The timechart data is located in the state machine
         * of the View component to make it part of the undo / redo
         * functions there.
         */

        this.timeIntervalSelector = interpret(this.timeIntervalSelectorMachine);
        this.timeIntervalSelector.subscribe((state: StateFrom<typeof this.timeIntervalSelectorMachine>) => {
            console.log(`+++ TimeChart: ${state.value}`);
            this.setState({ ...this.state, selection: state.context.selection });

            /**
             * In state "select_time_intervals" reset the state machine to
             * initial state, collect the case ids for the selected time / data
             * intervals and send them to the View component.
             */
            if (state.value === 'select_time_intervals') {
                this.timeIntervalSelector.send('reset');
                let caseIds = state.context.selection.reduce((ids: number[], idx) => {
                    const chartData = this.state.chartData;
                    if (chartData) {
                        ids.push(...chartData.cases[idx]);
                    }
                    return ids;
                }, [] as number[])
                caseIds = [...new Set([...caseIds])];  // make unique as set, reconvert to array
                this.props.onSelect({ caseIds, controlId: "timechart", controlState: undefined });
            }
        })
        this.state = {
            chartData: null,
            displayOptions: false,
            selection: this.timeIntervalSelectorMachine.initialState.context.selection,
            chartOptions: { type: defaultChartType, clampToZero: false, fixMinMax: false, minmaxDuration: { min: -1, max: -1 }, minmaxNumber: { min: -1, max: -1 } }
        }
    }

    setOptions(options: PartialTimeChartOptions) {
        this.setState({ ...this.state, chartOptions: { ...this.state.chartOptions, ...options } })
    }

    componentDidMount = () => {
        /** start the state machine service */
        this.timeIntervalSelector.start();
        const caseIds = this.props.graph.casesInfo.map((c) => c.id);
        getChartData(this.props.project.eventsFilePath, caseIds, this.state.chartOptions).then(chartData => this.setState({ ...this.state, chartData }));
    }

    shouldComponentUpdate(nextProps: Readonly<TimeChartProps>): boolean {
        /** skip update if graph changed. It will be rendered by setting a new state after getting the data */
        if ((this.props.graph !== nextProps.graph)) {
            const caseIds = nextProps.graph.casesInfo.map((c) => c.id);
            getChartData(this.props.project.eventsFilePath, caseIds, this.state.chartOptions)
                .then(chartData => {
                    this.setState({ ...this.state, chartData })
                })
                .catch(err => {
                    console.log(err);
                });
            return false;
        }
        else {
            return true
        }
    }

    componentDidUpdate(prevProps: Readonly<TimeChartProps>, prevState: Readonly<TimeChartState>): void {
        if (this.state.chartOptions.type !== prevState.chartOptions.type) {
            const caseIds = this.props.graph.casesInfo.map((c) => c.id);
            getChartData(this.props.project.eventsFilePath, caseIds, this.state.chartOptions)
                .then(chartData => this.setState({ ...this.state, chartData }))
                .catch(err => {
                    console.log(err);
                });
        }
    }

    componentWillUnmount = () => {
        /** stop the state machine service */
        this.timeIntervalSelector.stop();
    }

    render() {
        console.log(`*** Render TimeChart`)
        const intl = this.props.intl;
        const chartData = this.state.chartData;
        if (chartData === null) return null;

        const timeDurationFormats = [
            {
                duration: YEAR, label: intl.formatMessage({
                    id: "timechart.yaxis.unit.year",
                    defaultMessage: 'y',
                    description: "Y-Axis-Label for year time units.",
                })
            },
            {
                duration: MONTH, label: intl.formatMessage({
                    id: "timechart.yaxis.unit.month",
                    defaultMessage: 'm',
                    description: "Y-Axis-Label for month time units.",
                })
            },
            {
                duration: DAY, label: intl.formatMessage({
                    id: "timechart.yaxis.unit.day",
                    defaultMessage: 'd',
                    description: "Y-Axis-Label for day time units.",
                })
            },
            {
                duration: HOUR, label: intl.formatMessage({
                    id: "timechart.yaxis.unit.hour",
                    defaultMessage: 'h',
                    description: "Y-Axis-Label for hour time units.",
                })
            },
            {
                duration: MIN, label: intl.formatMessage({
                    id: "timechart.yaxis.unit.minute",
                    defaultMessage: 'min',
                    description: "Y-Axis-Label for minute time units.",
                })
            },
            {
                duration: SEC, label: intl.formatMessage({
                    id: "timechart.yaxis.unit.second",
                    defaultMessage: 'sec',
                    description: "Y-Axis-Label for second time units.",
                })
            },
        ]

        /**
         * Setup x-Axis-Label based on props
         * @returns
         */
        const timeLabel = () => {
            let xunit = "??";
            if (chartData) {
                xunit = chartData.xunit;
            }
            switch (xunit) {
                case "mi":
                    return intl.formatMessage({
                        id: "timechart.xaxis.unit.minute",
                        defaultMessage: 'Minutes',
                        description: "X-Axis-Label for hour time units.",
                    })
                    break;
                case "h":
                    return intl.formatMessage({
                        id: "timechart.xaxis.unit.hour",
                        defaultMessage: 'Hours',
                        description: "X-Axis-Label for hour time units.",
                    })
                    break;
                case "d":
                    return intl.formatMessage({
                        id: "timechart.xaxis.unit.day",
                        defaultMessage: 'Date',
                        description: "X-Axis-Label for day time unit.",
                    })
                    break;
                case "w":
                    return intl.formatMessage({
                        id: "timechart.xaxis.unit.week",
                        defaultMessage: 'Calendar Week',
                        description: "X-Axis-Label for week time unit.",
                    })
                    break;
                case "m":
                    return intl.formatMessage({
                        id: "timechart.xaxis.unit.month",
                        defaultMessage: 'Month',
                        description: "X-Axis-Label for month time unit.",
                    })
                    break;
                default:
                    return intl.formatMessage({
                        id: "timechart.xaxis.unit.unknown",
                        defaultMessage: 'Unknown {unit}',
                        description: "X-Axis-Label for unknown time unit.",
                    }, { unit: xunit })
            }
        }

        const durationLabel = () => {
            let xunit = "??";
            if (chartData) {
                xunit = chartData.xunit;
            }
            switch (xunit) {
                case "mi":
                    return intl.formatMessage({
                        id: "timechart.xaxis.dur.minute",
                        defaultMessage: 'Minutes',
                        description: "X-Axis-Label for hour time units.",
                    })
                    break;
                case "h":
                    return intl.formatMessage({
                        id: "timechart.xaxis.dur.hour",
                        defaultMessage: 'Hours',
                        description: "X-Axis-Label for hour time units.",
                    })
                    break;
                case "d":
                    return intl.formatMessage({
                        id: "timechart.xaxis.dur.day",
                        defaultMessage: 'Days',
                        description: "X-Axis-Label for day time unit.",
                    })
                    break;
                case "w":
                    return intl.formatMessage({
                        id: "timechart.xaxis.dur.week",
                        defaultMessage: 'Wochen',
                        description: "X-Axis-Label for week time unit.",
                    })
                    break;
                default:
                    return intl.formatMessage({
                        id: "timechart.xaxis.unit.unknown",
                        defaultMessage: 'Unknown {unit}',
                        description: "X-Axis-Label for unknown time unit.",
                    }, { unit: xunit })
            }
        }

        /**
         * setup chart options
         */
        const options: TimeChartOptions = {
            ...this.state.chartOptions,
            maintainAspectRatio: false,
            animation: {
                duration: 0,
            },
            scales: {
                x: {
                    title: {
                        display: true,
                    },
                },
                y1: {
                    position: 'left',
                    title: {
                        display: true,
                        text: intl.formatMessage({
                            id: "timechart.yaxis.duration",
                            defaultMessage: 'Duration [days]',
                            description: "Y-Axis-Label for duration.",
                        })
                    },
                    ticks: {
                    }
                },
            },
            onClick: (e: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
                if (e.native !== null) {
                    const points = chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, true);
                    const type: 'ctrl_select' | 'range_select' = (e.native as PointerEvent).ctrlKey ? 'ctrl_select' : 'range_select';
                    if (points.length) {
                        this.timeIntervalSelector.send({
                            type,
                            timeIntervalIndex: points[0].index
                        })
                    }
                }

            },
            type: this.state.chartOptions.type,
        };

        if (chartData) {

            const data: ChartData = {
                labels: chartData.labels.slice(0, max),
                datasets: []
            }
            // const data: ChartData<'line'|'bar'>{
            //     labels: string[],
            //     datasets: ChartOptions[],
            // } = {
            //     labels: chartData.labels.slice(0, max),
            //     datasets: [],
            // };

            const chartTypeNames: { [key in ChartType]: string } = {
                START_INTERVAL: intl.formatMessage({
                    id: "timechart.charttypes.start_interval",
                    defaultMessage: 'Interval starts',
                    description: "Label for chart type, which show informatoin about processes starting in the intervals.",
                }),
                COVER_INTERVAL: intl.formatMessage({
                    id: "timechart.charttypes.cover_interval",
                    defaultMessage: 'Interval coverage',
                    description: "Label for chart type, which show informatoin about processes covering the intervals.",
                }),
                RUNTIME_DIST: intl.formatMessage({
                    id: "timechart.charttypes.runtime_dist",
                    defaultMessage: 'Runtime distribution',
                    description: "Label for chart type, which shows information about the distribution of run times of processes.",
                })
            }

            let durations: (number | null)[];
            switch (this.state.chartOptions.type) {
                case 'START_INTERVAL':
                case 'COVER_INTERVAL': {

                    /** data and options for axis 1 */

                    durations = chartData.durations.slice(0, max);
                    data.datasets.push({
                        type: 'line',
                        label: intl.formatMessage({
                            id: "timechart.dataset.durations",
                            defaultMessage: '~ case durations [days]',
                            description: "Datasetlabel for case durations.",
                        }),
                        borderColor: axis1Color,
                        backgroundColor: axis1Background,
                        borderWidth: 2,
                        fill: true,
                        data: durations,
                        yAxisID: 'y1',
                        cubicInterpolationMode: 'monotone',
                    });

                    if (options.scales && options.scales.x && options.scales.x.title && options.scales.y1 && options.scales.y1.title) {

                        options.scales.x.title.text = (this.state.chartOptions.type
                            ? chartTypeNames[this.state.chartOptions.type]
                            : "")
                            + " "
                            + intl.formatMessage({
                                id: "timechart.xaxis.foreach",
                                defaultMessage: 'for each',
                                description: "Translation of 'for each'",
                            })
                            + " "
                            + timeLabel();

                        options.scales.y1.min = this.state.chartOptions.fixMinMax ?
                            this.state.chartOptions.minmaxDuration.min :
                            this.state.chartOptions.clampToZero ?
                                0 :
                                Math.min.apply(this, durations as number[]);
                        options.scales.y1.max = this.state.chartOptions.fixMinMax ?
                            this.state.chartOptions.minmaxDuration.max :
                            Math.max.apply(this, durations as number[]);

                        options.scales.y1.title.color = axis1Color;

                        options.scales.y1.afterBuildTicks = (axis) => {
                            const span = axis.max - axis.min;
                            const tmti = timeMagnitudeTickIntervalss.find((tmt, idx) => {
                                const tickInterval = tmt.timeSpanTicks[tmt.timeSpanTicks.length - 1];
                                return span / tmt.mag <= tickInterval * MAX_TICKS || (idx === timeMagnitudeTickIntervalss.length - 1);
                            });
                            if (tmti) {
                                const magValue = span / tmti.mag;

                                let tickInterval = tmti.timeSpanTicks.find((tickInterval, idx) => {
                                    return magValue / tickInterval <= MAX_TICKS || idx === tmti.timeSpanTicks.length - 1
                                });
                                if (tickInterval) {
                                    const ticks = Math.ceil(magValue / tickInterval);
                                    tickInterval *= tmti.mag;

                                    /* compute min on the basis of the tick increment */
                                    axis.min = Math.floor(axis.min / tickInterval) * tickInterval;
                                    axis.max = axis.min + tickInterval * ticks;

                                    let value = axis.min;
                                    axis.ticks = [{ value, label: (value / tmti.mag).toString() }];
                                    for (let i = 0; i < ticks; i++) {
                                        value += tickInterval;
                                        axis.ticks.push({ value, label: (value / tmti.mag).toString() });
                                    }
                                }
                            }
                        }

                        if (options.scales.y1.ticks) {
                            options.scales.y1.ticks.callback = (duration: number | string) => {
                                duration = typeof duration == "string" ? parseInt(duration) : duration
                                const parts: string[] = [];
                                for (const tdf of timeDurationFormats) {
                                    const val = Math.floor(duration / tdf.duration);
                                    if (val > 0) parts.push(val + tdf.label);
                                    duration -= val * tdf.duration;
                                    if (parts.length === 2) break;
                                }
                                return parts.join(" ");
                            }
                            options.scales.y1.ticks.color = axis1Color;
                        }

                        /** data and options for axis 2 */

                        const backgroundColors = new Array(chartData.labels.length).fill(axis2Color);
                        const selection = this.state.selection;
                        if (selection) {
                            selection.forEach(idx => backgroundColors[idx] = selectColor);
                        }

                        const values = chartData.values.slice(0, max);
                        data.datasets.push({
                            type: 'bar',
                            label: intl.formatMessage({
                                id: "timechart.dataset.cases",
                                defaultMessage: '# of running cases',
                                description: "Datasetlabel for number of running cases.",
                            }),
                            backgroundColor: backgroundColors,
                            data: values,
                            yAxisID: 'y2',
                        } as ChartDataset<"bar">);

                        options.scales.y2 = {
                            afterBuildTicks: (axis) => {
                                const span = axis.max - axis.min;
                                const magnitude = Math.floor(Math.log10(span));
                                const magValue = span / Math.pow(10, magnitude); /* 0 - 10 */
                                let tickInterval = numTickIncrements.find((nst, idx) => {
                                    return magValue / nst <= MAX_TICKS || idx === numTickIncrements.length - 1
                                });
                                if (tickInterval) {
                                    const ticks = Math.ceil(magValue / tickInterval);
                                    tickInterval *= Math.pow(10, magnitude);

                                    /* compute min on the basis of the tick increment */
                                    axis.min = Math.floor(axis.min / tickInterval) * tickInterval;
                                    axis.max = axis.min + tickInterval * ticks;

                                    let value = axis.min;
                                    axis.ticks = [{ value, label: value.toString() }];
                                    for (let i = 0; i < ticks; i++) {
                                        value += tickInterval;
                                        axis.ticks.push({ value, label: value.toString() });
                                    }
                                }
                            },
                            position: 'right',
                            min: this.state.chartOptions.fixMinMax ?
                                this.state.chartOptions.minmaxNumber.min :
                                this.state.chartOptions.clampToZero ?
                                    0 :
                                    Math.min.apply(this, values),
                            max: this.state.chartOptions.fixMinMax ?
                                this.state.chartOptions.minmaxNumber.max :
                                Math.max.apply(this, values),
                            title: {
                                display: true,
                                color: axis2Color,
                                text: intl.formatMessage({
                                    id: "timechart.yaxis.cases",
                                    defaultMessage: 'cases [#]',
                                    description: "Y-Axis-Label for number of cases.",
                                })
                            },
                            ticks: {
                                color: axis2Color,
                            }
                        };
                    }
                    break;
                }

                case 'RUNTIME_DIST': {
                    const backgroundColors = new Array(chartData.labels.length).fill(axis1Color);
                    const selection = this.state.selection;
                    if (selection) {
                        selection.forEach(idx => backgroundColors[idx] = selectColor);
                    }

                    durations = chartData.durations.slice(0, max);
                    data.datasets.push({
                        type: 'bar',
                        label: intl.formatMessage({
                            id: "timechart.dataset.durations",
                            defaultMessage: '~ case durations [days]',
                            description: "Datasetlabel for case durations.",
                        }),
                        borderColor: axis1Color,
                        backgroundColor: backgroundColors,
                        data: durations,
                        yAxisID: 'y1',
                    } as ChartDataset<'bar'>);

                    if (options.scales && options.scales.x && options.scales.x.title && options.scales.y1 && options.scales.y1.title && options.scales.y1.ticks) {
                        options.scales.y1.type = "logarithmic";
                        options.scales.y1.title.text = intl.formatMessage({
                            id: "timechart.yaxis.count",
                            defaultMessage: 'Anzahl [#]',
                            description: "Y-Axis-Label for count.",
                        })

                        options.scales.x.title.text = chartTypeNames[this.state.chartOptions.type]
                            + " [" + durationLabel() + "]"
                        options.scales.y1.ticks.color = axis1Color;
                        options.scales.y1.title.color = axis1Color;

                        options.scales.y1.afterBuildTicks = (axis) => {
                            console.log(axis);
                            // axis.min = 0;
                            axis.ticks = axis.ticks.filter(t => t.value >= 1)
                        }
                    }

                    break;
                }
            }

            return (
                <div id="chartWrapper">
                    {this.state.displayOptions &&
                        <div id="chartOptions">
                            <Select<ChartType>
                                activeItem={this.state.chartOptions.type}
                                //id="chart-type"
                                filterable={false}
                                items={['START_INTERVAL', 'COVER_INTERVAL', 'RUNTIME_DIST']}
                                popoverProps={{ minimal: true }}
                                onItemSelect={(type) => {
                                    if (this.state.chartOptions.type !== type) {
                                        console.log(type);
                                        this.setOptions({ type })
                                    }
                                }}
                                itemRenderer={(item: ChartType, { handleClick, modifiers }) => {
                                    return (
                                        <MenuItem
                                            active={modifiers.active}
                                            key={item}
                                            onClick={handleClick}
                                            text={chartTypeNames[item]}
                                        />
                                    )
                                }}
                            >
                                <Button
                                    text={this.state.chartOptions.type
                                        ? chartTypeNames[this.state.chartOptions.type]
                                        : intl.formatMessage({
                                            id: "ui.unselected",
                                            defaultMessage: "(No selection)",
                                            description: "Label for unset selection.",
                                        })}
                                    rightIcon="caret-down"
                                />
                            </Select>
                            <Checkbox
                                checked={this.state.chartOptions.clampToZero}
                                label={intl.formatMessage({
                                    id: "ui.clampToZero",
                                    defaultMessage: "Clamp scales to Zero",
                                    description: "Label to clamp scales to zero.",
                                })}
                                disabled={this.state.chartOptions.type === 'RUNTIME_DIST'}
                                onChange={() => {
                                    this.setOptions({ clampToZero: !this.state.chartOptions.clampToZero })
                                }}
                            />
                            <Checkbox
                                checked={this.state.chartOptions.fixMinMax}
                                label={intl.formatMessage({
                                    id: "ui.fixMinMax",
                                    defaultMessage: "Fix vertical min/max",
                                    description: "Label to fix vertical min/max.",
                                })}
                                disabled={this.state.chartOptions.type === 'RUNTIME_DIST'}
                                onChange={() => {
                                    const fixMinMax = !this.state.chartOptions.fixMinMax;
                                    let optionsUpdate: object = { fixMinMax };
                                    if (fixMinMax && options.scales && options.scales.y1) {
                                        const minmaxDuration: MinMax = { min: options.scales.y1.min as number, max: options.scales.y1.max as number }
                                        optionsUpdate = { ...optionsUpdate, minmaxDuration }

                                        if (options.scales.y2) {
                                            const minmaxNumber: MinMax = { min: options.scales.y2.min as number, max: options.scales.y2.max as number }
                                            optionsUpdate = { ...optionsUpdate, minmaxNumber }
                                        }
                                        this.setOptions(optionsUpdate);
                                    }
                                    else {
                                        this.setOptions({ fixMinMax })
                                    }
                                }}
                            />
                        </div>
                    }
                    <div id="chartContent">
                        <Bar
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data={data as any}
                            options={options}
                        />
                        <Button
                            className={"btn-options"}
                            icon="cog"
                            onClick={() => this.setState({ ...this.state, displayOptions: !this.state.displayOptions })}
                        />
                    </div>
                </div>
            );
        }
        else {
            return <div><span>Waiting for Data...</span></div>
        }
    }
}
const v = injectIntl(TimeChart);
export { v as TimeChart, ChartType as ChartTypes, defaultChartType };